<template>
  <section class="search" data-t="search">
    <form class="search-form">
      <StInput
        v-model="searchValue"
        :placeholder="t('search.buttonPlacholder')"
        size="s"
        data-t="search-input"
        icon-prefix="search"
      >
        <template #icon-posfix>
          <StIcon
            v-if="searchValue"
            :size="16"
            class="reset-icon"
            name="cross-large"
            @click="resetSearch"
          />
        </template>
      </StInput>
      <button class="cancel-button" @click.prevent="handleClose">
        {{ t('search.cancel') }}
      </button>
    </form>
    <div class="search-wrapper">
      <div class="result">
        <div
          v-if="searchValue.length >= 3"
          class="tabs-wrapper"
          :class="{
            'arrived-top': arrivedState.top,
            'border-bottom': searchValue.length >= 3,
          }"
        >
          <StTabs v-model="selectedTab" :tabs="tabs" type="mobile-l" />
        </div>
        <Transition>
          <div v-if="searchValue.length >= 3" ref="el" class="result-list">
            <CasinoSearchResult
              v-if="selectedTab === 'casino' && casinoGames.length"
              :items="casinoGames"
              is-mobile
            />
            <MBookmakerSearchResult
              v-else-if="selectedTab === 'sport' && sportEvents.length"
              :items="sportEvents"
            />
            <MBookmakerSearchResult
              v-else-if="
                selectedTab === 'cybersport' && cybersportEvents.length
              "
              :items="cybersportEvents"
            />
            <div v-else-if="!isLoading" class="nothing-found">
              <StIcon class="nothing-icon" name="search-none" size="36" />
              <h2 class="nothing-header">{{ t('search.nothing') }}</h2>
              <p class="nothing-info">{{ t('search.changeRequest') }}</p>
            </div>
          </div>
        </Transition>
      </div>
      <Transition>
        <div
          v-if="searchValue.length < 3"
          class="empty-search"
          data-t="empty-search"
        >
          <img height="113" src="./empty.png" width="200" />
          <p>{{ t('search.empty') }}</p>
        </div>
      </Transition>
    </div>
  </section>
</template>

<script lang="ts" setup>
import MBookmakerSearchResult from '@st/bookmaker/components/BookmakerSearchResult/MBookmakerSearchResult.vue'
import CasinoSearchResult from '@st/casino/components/CasinoSearchResult/CasinoSearchResult.vue'
import { useSearch } from './useSearch'

const { t } = useI18n()
const {
  resetSearch,
  searchValue,
  tabs,
  selectedTab,
  sportEvents,
  cybersportEvents,
  casinoGames,
  isLoading,
} = useSearch()

const router = useRouter()
function handleClose() {
  router.replace({ query: {} })
}
const el = ref<HTMLDivElement>()
const { arrivedState } = useScroll(el)
</script>

<style scoped>
.search {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;

  background: var(--background-base);
}

.search-form {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-100);
}

.reset-icon {
  color: var(--text-tertiary);
}

.result {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
}

.tabs-wrapper {
  position: relative;

  margin-top: var(--spacing-100);
  padding: var(--spacing-100);

  background: var(--background-primary);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;

  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -32.5px;
    left: 0;

    height: 32px;

    opacity: 0;
    background: linear-gradient(#111118 0%, rgb(17 17 24 / 0%) 100%);
  }

  &:not(.arrived-top) {
    &::before {
      opacity: 1;
    }
  }

  &.border-bottom {
    border-bottom: var(--border-width-light, 0.5px) solid
      var(--border-secondary);
  }
}

.result-list {
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  height: 100%;
  margin-right: calc(var(--spacing-200) * -1);
  padding-right: var(--spacing-200);

  background-color: var(--background-primary);
}

.search-wrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  min-height: 0;

  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.empty-search {
  position: absolute;
  top: 50%;
  right: var(--spacing-200);
  left: var(--spacing-200);
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    margin-top: var(--spacing-200);
    font: var(--mobile-text-content-regular);
    color: var(--text-tertiary);
  }
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.25s ease-in-out,
    transform 0.35s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.nothing-found {
  margin: auto;
  text-align: center;
}

.nothing-icon {
  margin-right: var(--spacing-050);
  margin-bottom: var(--spacing-200);
  opacity: 0.24;
}

.nothing-header {
  margin: 0;
  margin-bottom: var(--spacing-050);
  font: var(--mobile-headline-semibold);
}

.nothing-info {
  margin: 0;
  font: var(--mobile-caption-1-regular);
  color: var(--text-tertiary);
}

.cancel-button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100);

  font: var(--mobile-text-regular);
  color: var(--text-primary);

  background-color: transparent;
  border: none;
  box-shadow: none;
}
</style>
